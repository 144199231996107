.listingWatermark {
  position: absolute;
  color: #ffffffd6;
  z-index: 1;
  width: 100%;
  text-align: center;
  font-size: 40px;
  transform: rotate(-40deg);
  font-weight: bold;
  top: 35%;
}
