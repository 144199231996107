.root {
}

.inputError {
  border: var(--borderErrorField);
}

.requiredField {
  color: var(--colorFail);
}
